import { graphql, useStaticQuery } from "gatsby"

const useTeams = function() {
  const data = useStaticQuery(
    graphql`
      query clubTeamsQuery {
        markdownRemark(fields: {slug: {eq: "/.data/block/teams/"}}) {
          frontmatter {
            vno
            heading
            teams {
              name
              mno
              group
              lead
              phone
              captain
            }
          }
        }
      }
    `
  )
  return data.markdownRemark.frontmatter
}

export default useTeams
