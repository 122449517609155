import React from "react"
import Header from "../components/Header"
import { SectionHeading, TextImageSplit } from "../components/Sections"
import { TeamCaptains } from "../components/Teams"
import { UpcomingMatches } from "../components/Matches"

function ClubPageTemplate({ heading, subheading, intro, preview='' }) {
  return (
    <>
      <Header heading={heading} subheading={subheading} />
      <TextImageSplit image={intro.image} hideSmall="">
        <SectionHeading>{intro.heading}</SectionHeading>
          <div
            className="prose dark:prose-invert sm:pr-8 pb-4 sm:mb-0 text-left leading-relaxed text-lg"
            dangerouslySetInnerHTML={{ __html: intro.description.html }}
          />
      </TextImageSplit>
      { preview ? '' : <TeamCaptains/> }
      { preview ? '' : <UpcomingMatches/> }
    </>
  )
}

export default ClubPageTemplate
