import { graphql, useStaticQuery } from "gatsby"

export function useVNo() {
  const data = useStaticQuery(
    graphql`
      query vNoQuery {
        vno: markdownRemark(fields: {slug: {eq: "/.data/block/teams/"}}) {
          frontmatter {
            vno
          }
        }
      }
    `
  )
  return {
    vno: data.vno.frontmatter.vno,
  }
}
