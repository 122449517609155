import { graphql, useStaticQuery } from "gatsby"

const useMatches = function() {
  const data = useStaticQuery(
    graphql`
      query clubMatchesQuery {
        allMatch(
          sort: {fields: datetime, order: ASC}
        ) {
          edges {
            node {
              date,
              time,
              wday,
              home
              guest
              group
              datetime
              displayDate: datetime(formatString: "DD. MMM", locale: "de-DE")
              mno
              isHome
            }
          }
        }
        markdownRemark(fields: {slug: {eq: "/.data/block/heimspiele/"}}) {
          frontmatter {
            heading
            text1 {
              html
            }
          }
        }
      }
    `
  )
  return {
    matches: data.allMatch.edges.map(({ node }) => node),
    intro: {
      ...data.markdownRemark.frontmatter
    },
  }
}

export default useMatches
