import React from "react"
import { Container, SectionHeading } from "./Sections"
import useTeams from "../hooks/useTeams"
import { TeamMatches } from "./Matches"

export function TeamDetail({ vno, name, mno, group, captain, phone, mail }, idx) {
  return (
    <>
    <div className="flex flex-col">
      <h3>
        <a className="no-underline"
          href={`https://www.rlp-tennis.de/liga/vereine/verein/mannschaften/mannschaft/v/${vno}/m/${mno}.html`}
          title={`Spielplan ${name}`}
        >{ name }</a>
      </h3>
      <div className="whitespace-nowrap">
        { group }
      </div>
      <div className="whitespace-nowrap">
        { captain }
      </div>
      <div className="whitespace-nowrap">
        <a href={`tel:${phone}`} title="anrufen" className="font-normal text-inherit no-underline sm:underline">
          { phone }
        </a>
      </div>
    </div>
    <TeamMatches mno={mno} className=""/>
    </>
  )
}

function Teams() {
  const { vno, heading, teams } = useTeams()
  return (
      <Container className="max-w-7xl">
        <SectionHeading>{ heading }</SectionHeading>
        <div className="max-w-7xl prose dark:prose-invert">
          <div className="">
            {teams.map(t => <TeamDetail vno={vno} {...t} key={t.mno}/>)}
          </div>
        </div>
      </Container>
  )
}

function TeamCaptain({ vno, name, mno, group, captain, phone, mail }) {
  return (
    <div className="sm:grid sm:grid-cols-4 sm:px-2 sm:py-2 sm:odd:bg-none sm:even:bg-gray-100 dark:sm:even:bg-gray-600">
      <div className="font-bold whitespace-nowrap">
        <a
          href={`https://www.rlp-tennis.de/liga/vereine/verein/mannschaften/mannschaft/v/${vno}/m/${mno}.html`}
          title={`Spielplan ${name}`}
        >{ name }</a>
      </div>
      <div className="whitespace-nowrap sm:text-right sm:order-4">
        { group }
      </div>
      <div className="">
        { captain }
      </div>
      <div className="whitespace-nowrap sm:text-center">
        <a href={`tel:${phone}`} title="anrufen" className="font-normal text-inherit no-underline sm:underline">
          { phone }
        </a>
      </div>
    </div>
  )
}

export function TeamCaptainsTemplate({ vno, heading, teams }) {
  return (
      <Container className="max-w-7xl w-full">
        <SectionHeading>{ heading }</SectionHeading>
        <div className="prose dark:prose-invert mt-8 max-w-7xl">
          <div className="grid grid-cols-2 sm:grid-cols-1 gap-x-4 gap-y-8 sm:gap-y-1 sm:-mx-2">
            {teams.map(t => <TeamCaptain vno={vno} {...t} key={t.mno}/>)}
          </div>
        </div>
      </Container>
  )
}

export function TeamCaptains() {
  const props = useTeams()
  return <TeamCaptainsTemplate {...props}/>
}

export default Teams
