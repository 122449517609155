import React from "react"
import { graphql } from "gatsby"
import MyHelmet from "../components/MyHelmet"
import ClubPageTemplate from "./ClubPageTemplate"

const ClubPage = ({ data }) => {
  const { md } = data
  const { fm } = md

  return (
    <>
      <MyHelmet title={fm.title} description={fm.subheading} />
      <ClubPageTemplate
        heading={fm.heading}
        subheading={fm.subheading}
        intro={fm.intro}
        image={fm.image}
      />
    </>
  )
}

export default ClubPage

export const clubPageQuery = graphql`
  query ClubPage($id: String!) {
    md: markdownRemark(id: { eq: $id }) {
      html
      fm: frontmatter {
        title
        heading
        subheading
        intro {
          heading
          image: imageAlt {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 1216, placeholder: BLURRED)
              }
            }
          }
          description {
            html
          }
        }
      }
    }
  }
`
